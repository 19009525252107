import React from 'react'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

interface WorkmethodProps {
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Components
}

const StyledWorkmethod = styled.section<{ overflow: string }>`
  background-color: ${({ theme }) => theme.color.primary};
  padding: 60px 200px 60px 60px;
  border-radius: 10px;
  height: 100%;
  position: relative;

  & p {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  ${({ overflow, theme }) =>
    overflow === 'start' &&
    css`
      &:before {
        content: '';
        background-color: ${theme.color.primary};
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        right: calc(100% - 10px);
        border-radius: 10px 0 0 10px;
      }
    `}

  ${({ overflow, theme }) =>
    overflow === 'end' &&
    css`
      &:before {
        content: '';
        background-color: ${theme.color.primary};
        height: 100%;
        width: 500px;
        position: absolute;
        top: 0;
        left: calc(100% - 10px);
        border-radius: 0 10px 10px 0;
      }
    `}

  @media (max-width: 1199px) {
    padding: 50px;
  }

  @media (max-width: 991px) {
    padding: 30px 170px;

    &:before {
      content: none;
    }

    & p {
      text-align: center;
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    padding: 30px 100px;
  }

  @media (max-width: 575px) {
    padding: 30px;
  }
`

const IconWrapper = styled.div`
  @media (min-width: 992px) {
    min-width: 185px;
  }
`

const Workmethod: React.FC<WorkmethodProps> = ({ fields }) => (
  <StyledWorkmethod overflow={fields.alignment || 'center'}>
    <div className="d-flex flex-column justify-content-between h-100">
      {fields.usps?.map((usp, index) => (
        <div
          className={`d-flex flex-lg-nowrap flex-wrap justify-content-lg-start justify-content-center align-items-center ${
            index + 1 === fields.usps?.length ? `` : `mb-lg-0 mb-5`
          }`}
          key={index}
        >
          <IconWrapper className="mb-lg-0 mb-2">
            <Plaatjie image={usp?.icon} alt="" />
          </IconWrapper>
          <ParseContent content={usp?.description || ''} />
        </div>
      ))}
    </div>
  </StyledWorkmethod>
)

export default Workmethod
